




















































































import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import { datatypes } from "@/components/DataTableGenerico/datatypes";
import fichajes_empleadoModule from "@/store/modules/fichajes_empleado-module";
import { fichajes_empleado } from "@/shared/dtos/fichajes_empleado";
import tipos_fichajeModule from "@/store/modules/tipos_fichaje-module";
import usuarioModule from "@/store/modules/usuario-module";
import centro_trabajoModule from "@/store/modules/centro_trabajo-module";
import fichajes_empleado_historialModule from "@/store/modules/fichajes_empleado_historial-module";
import variablesModule from "@/store/modules/variablesModule";

@Component({
  components: {
  },
})
export default class fichajes_empleadoFormulario extends Mixins(
  HelperDinamicallyForm
) {
  public item_pantalla: fichajes_empleado = new fichajes_empleado();
  public label_centros_trabajo: string = "Centro trabajo";
  public valores_calendario: any = null;

  public UpdateHandler() {
    console.log("UpdateHandler");
  }

  public async OnCreated() {
    this.ShowLoading();
    variablesModule.getVariable("centros_trabajo").then((x: string) => {
      this.label_centros_trabajo = x;
    });
    if (this.getparamId().HayParametro) {
      await fichajes_empleadoModule.getfichajes_empleado(
        this.getparamId().Parametro
      );
      this.item_pantalla = fichajes_empleadoModule.fichajes_empleado;
      await fichajes_empleado_historialModule.getAllModifi(
        this.item_pantalla.id
      );
      await usuarioModule.getusuarios();
    } else {
      this.item_pantalla.datecreated = new Date();
      this.item_pantalla.dateupdated = new Date();
      this.item_pantalla.createdby = usuarioModule.mi_usuario.id;
      this.item_pantalla.updatedby = usuarioModule.mi_usuario.id;
      this.item_pantalla.fecha_entrada = new Date();
      this.item_pantalla.fecha_salida = new Date();
      fichajes_empleado_historialModule.onGetfichajes_empleado_historiales([]);
    }
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    await tipos_fichajeModule.gettipos_fichajes();
    await usuarioModule.getusuarios();
    await centro_trabajoModule.getcentro_trabajos_base();
    this.HideLoading();
  }

  public get modificaciones_historico() {
    return fichajes_empleado_historialModule.fichajes_empleado_historiales;
  }

  public obtenerNombreUsuario(idUsuario: number): string {
    const usuario = usuarioModule.usuarios.find(
      (user) => user.id === idUsuario
    );
    return usuario ? usuario.nombre : "Usuario desconocido";
  }

  public obtenerNombreCentroTrabajo(id_centro: number): string {
    const CentroEncontrado = centro_trabajoModule.centro_trabajos.find(
      (centro) => centro.id === id_centro
    );
    return CentroEncontrado ? CentroEncontrado.nombre : "Centro desconocido";
  }

  public transformarValor(campo: string, valor: string | number): string {
    if (campo.toLowerCase() === "centro trabajo") {
      return this.obtenerNombreCentroTrabajo(Number(valor));
    }
    return valor.toString();
  }
  public campomodificado(campo: string): string {
    if (campo.toLowerCase() === "centro trabajo") {
      return this.label_centros_trabajo;
    }
    return campo;
  }

  public formatFecha(fecha: string | Date): string {
    const dateObj = new Date(fecha);
    return (
      dateObj.toLocaleDateString() +
      " " +
      dateObj.toLocaleTimeString("es-ES", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Asegura el formato de 24 horas
      })
    );
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultDateTime(
      new Date(this.item_pantalla.fecha_entrada),
      "Fecha entrada",
      "fecha_entrada"
    )
      .isRequired()
      .Dimesiones(12, 6, 4);

    this.AddFormItemDataDefaultDateTime(
      new Date(this.item_pantalla.fecha_salida),
      "Fecha salida",
      "fecha_salida"
    )
      .isRequired()
      .Dimesiones(12, 6, 4);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_tipo_fichaje,
      "Tipo fichaje",
      "id_tipo_fichaje"
    )
      .Dimesiones(12, 6, 4)
      .isRequired()
      .CreateComboSingleCodigoDescripcionNames(
        tipos_fichajeModule.tipos_fichajes,
        "nombre",
        "id"
      );

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_centro_trabajo,
      this.label_centros_trabajo,
      "id_centro_trabajo"
    )
      .Dimesiones(12, 6, 4)
      .isRequired()
      .CreateComboSingleCodigoDescripcionNames(
        centro_trabajoModule.centro_trabajos,
        "nombre",
        "id"
      );

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_usuario,
      "Usuario fichaje",
      "id_usuario"
    )
      .Dimesiones(12, 6, 4)
      .isReadOnly(!this.EstoyCreado)
      .CreateComboSingleCodigoDescripcionNames(
        usuarioModule.usuarios,
        "nombre",
        "id"
      );

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.createdby,
      "Fichaje creado por",
      "createdby"
    )
      .Dimesiones(12, 6, 4)
      .isReadOnly()
      .CreateComboSingleCodigoDescripcionNames(
        usuarioModule.usuarios,
        "nombre",
        "id"
      );

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.updatedby,
      "Fichaje actualizado por",
      "updatedby"
    )
      .Dimesiones(12, 6, 4)
      .isReadOnly()
      .CreateComboSingleCodigoDescripcionNames(
        usuarioModule.usuarios,
        "nombre",
        "id"
      );

    this.AddFormItemDataDefaultDateTime(
      new Date(this.item_pantalla.datecreated),
      "Fecha creación",
      "datecreated"
    )
      .isReadOnly()
      .Dimesiones(12, 6, 4);
    this.AddFormItemDataDefaultDateTime(
      new Date(this.item_pantalla.dateupdated),
      "Fecha actualización",
      "dateupdated"
    )
      .isReadOnly()
      .Dimesiones(12, 6, 4);
    this.AddFormItemDataDefaultSlot(
      this.modificaciones_historico,
      "Historial de modificaciones",
      "modificaciones_historico",
      0
    ).CreateInNewROW();
  }

  public Insertar(object: fichajes_empleado) {
    fichajes_empleadoModule.guardarfichajes_empleado(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: fichajes_empleado) {
    fichajes_empleadoModule.modificarfichajes_empleado(object).then(() => {
      this.AllSaveOk(); //guardo correctamente y me cierro
    });
  }
}
