import { store } from '@/store/store';
import { fichajes_empleado_historial } from '@/shared/dtos/fichajes_empleado_historial';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'fichajes_empleado_historialModule',
    store,
    dynamic: true
})
class fichajes_empleado_historialModule extends VuexModule {
    public fichajes_empleado_historiales: fichajes_empleado_historial[] = [];
    public fichajes_empleado_historial: fichajes_empleado_historial = new fichajes_empleado_historial();

    @Action({ commit: 'onGetfichajes_empleado_historiales' })
    public async getfichajes_empleado_historiales() {
        return await ssmHttpService.get(API.fichajes_empleado_historial);
    }

    @Action({ commit: 'onGetfichajes_empleado_historial' })
    public async getfichajes_empleado_historial(id: any) {
        return await ssmHttpService.get(API.fichajes_empleado_historial + '/' + id);
    }

    @Action({ commit: 'onGetfichajes_empleado_historiales' })
    public async getAllModifi(id_fichaje: number) {
        return await ssmHttpService.get(`${API.fichajes_empleado_historial}/modificaciones/${id_fichaje}`);
    }
    @Action
    public async guardarfichajes_empleado_historial(fichajes_empleado_historial: fichajes_empleado_historial) {
       return await ssmHttpService.post(API.fichajes_empleado_historial, fichajes_empleado_historial.toJson());
    }

    @Action
    public async modificarfichajes_empleado_historial(fichajes_empleado_historial: fichajes_empleado_historial) {
        return await ssmHttpService.put(API.fichajes_empleado_historial + '/' + fichajes_empleado_historial.id, fichajes_empleado_historial);
    }

    @Action
    public async eliminarfichajes_empleado_historial(fichajes_empleado_historial: fichajes_empleado_historial) {
       return await ssmHttpService.delete(API.fichajes_empleado_historial + '/' + fichajes_empleado_historial.id, null, false);
    }

    @Mutation
    public onGetfichajes_empleado_historiales(res: fichajes_empleado_historial[]) {
        this.fichajes_empleado_historiales = res ? res.map((x) => new fichajes_empleado_historial(x)) : []
    }

    @Mutation
    public onGetfichajes_empleado_historial(res: fichajes_empleado_historial) {
        this.fichajes_empleado_historial = new fichajes_empleado_historial(res);
    }

}
export default getModule(fichajes_empleado_historialModule);